@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --push-size: 240px;
  --push-color: hsla(10, 90%, 40%, 1)
}

html, body {
  margin: 0;
  padding: 0;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  background: #000;
}

html, body, #root, .App, .container, .bells-container, .truck-container { height: 100%; overflow:hidden; }

h1 {
  font-family: 'Libre Baskerville', serif;
  font-weight: 400;
  margin: 0 0 6px;
  text-shadow: 0 0 6px rgba(0,0,0,.2)
}

p { margin: 0 0 48px; text-shadow: 0 0 6px rgba(0,0,0,.2)}
a { color: #fff;}
button, a { -webkit-tap-highlight-color: transparent; }

strong { font-weight: 400;}

.background {
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.bells-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;  
}

.bell {
  position: absolute;
  height: auto;
  -webkit-animation: showBell .8s ease-in;
          animation: showBell .8s ease-in;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.content {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  z-index: 3;
  overflow: hidden;
}

.button-wrapper {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer { 
  padding: 64px 64px 0;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
}

.push--flat {
  position: relative;
  display: inline-block;
  width: 240px;
  width: var(--push-size);
  height: 240px;
  height: var(--push-size);
  border: 0;
  margin: 1em;
  outline: none;
  background-color: hsla(10, 90%, 40%, 1);
  background-color: var(--push-color);
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: box-shadow 200ms;
  transition: box-shadow 200ms;
  box-shadow: 
  inset 0 7.5px 0 #da2e0b, inset 0 -7.5px 0 #aa2409, inset 0 0 0 7.5px #b32609, inset 0 0 0 20px #c2290a, inset 0 0 0 24px #611405, inset 0 0 0 26.0869565217px black, inset 0 0 0 32px rgba(247, 133, 110, 0.7), inset 0 0 0 43.6363636364px #c2290a, inset 0 96px 32px #aa2409, inset 0 0 24px 40px #911f08, 0 12px 0 rgba(0, 0, 0, 0.3);
}

.push--flat:after {
  content: '';
    position: absolute;
    bottom: 12px;
    left: 24px;
    display: block;
    width: 192px;
    height: 192px;
    border: 16px solid rgba(0, 0, 0, 0.3);
    border-width: 0 0 16px;
    border-radius: 50%;
    -webkit-transition-duration: 200ms;
    transition-duration: 200ms;
}

.push--flat:active,
.push--flat.is-pushed {
  box-shadow: inset 0 7.5px 0 #da2e0b, inset 0 -7.5px 0 #aa2409, inset 0 0 0 7.5px #b32609, inset 0 0 0 20px #c2290a, inset 0 0 0 24px #611405, inset 0 0 0 28.2352941176px black, inset 0 0 0 32px rgba(247, 133, 110, 0.2), inset 0 0 0 43.6363636364px #b32609, inset 0 96px 32px #9b2108, inset 0 0 24px 40px #791a06, 0 12px 0 rgba(0, 0, 0, 0.3);
  background-color: #b8270a;
  }

  .push--flat:active:after,
  .push--flat.is-pushed:after {
    bottom: 28px;
    border-width: 0;
  }

  .truck-container{
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  .truck {
    -webkit-transition: left 3s;
    transition: left 3s;
    -webkit-transition-timing-function: ease-in-out;
            transition-timing-function: ease-in-out;
    max-width: 20%;
    height: auto;
    bottom: 20%;
    position: absolute;
    z-index: 2;
    left: 120%;
  }

  @media only screen and (max-width: 600px) {
    strong { display: block;}
    footer {padding: 0 16px}
    .truck { bottom: 25%; max-width: 40%; }
  }

  @-webkit-keyframes showBell {
    0% {
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    50% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    100% {
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }

  @keyframes showBell {
    0% {
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
    }

    50% {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }

    100% {
      opacity: 0;
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }


